/* Provide sufficient contrast against white background */
a {
  color: #29CC9Eff;
}

.btn:focus,
.btn:active:focus,
.btn-link.nav-link:focus,
.form-control:focus,
.form-check-input:focus {
  box-shadow: 0 0 0 0.1rem white, 0 0 0 0.25rem #29CC9Eff;
}


code {
  color: #E01A76;
}

.btn-primary {
  color: #fff;
  background-color: #273952ff;
  border-color: #273952ff;
}


.btn-primary:hover {
  color: #fff;
  background-color: rgb(56, 93, 144);
  border-color: rgb(56, 93, 144);
}

.btn-gold,
#badge-gold {
  color: #B1F6F3ff;
  background-color: rgb(78, 104, 103);
  border-color: #B1F6F3ff;
}

.badge.bg-badge-gold {
  background-color: #e7f1ff;
  color: #053986;
  /*  background-color: #1b6ec2;
  color: white; */
}

.btn-gold:hover {
  color: rgb(69, 92, 91);
  background-color: rgb(129, 175, 173);
  border-color: rgb(69, 92, 91);
}

.breadcrumb-back {
  background-color: transparent !important;
}

.table-container {
  display: flex;
}

table {
  margin-right: 0px;
}

.f3fc {
  margin-top: 20px;
  background-color: rgb(247 247 247);
  border-radius: 5px;
  padding: 14px 21px 5px;
  border-color: rgb(229 229 229);
  border-style: solid;
  border-width: 0px;
}

.group-components {

  padding-left: 10px;
  padding-right: 10px;
  padding-top: 8px;
  padding-bottom: 8px;
  border-radius: 3px;
  border-color: grey;
  border-style: solid;
  border-width: 2px;
}

@media only screen and (max-width: 600px) {
  .card-img-top {
    object-fit: fill;
    height: 100%;
  }

  .center-mobile {
    text-align: center;
    margin-top: 20px;
  }
}

@media only screen and (min-width: 901px) {
  .card-img-top {
    object-fit: cover;
    height: 300px;
  }

  .level {
    margin-top: -45px;
  }
}

.on-brand {
  background-color: #273952ff;
}

.page {
  position: absolute;
  left: 15px;
  right: 15px;
}

.page-enter {
  opacity: 0;
  transform: scale(1.1);
}

.page-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: opacity 300ms, transform 300ms;
}

.page-exit {
  opacity: 1;
  transform: scale(1);
}

.page-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}

.btn:first-child:active {
  background-color: rgb(88, 133, 194);
}

.stats-table {
  --bs-table-bg: "white" !important;
  --bs-table-color: "white" !important;
  color: "white" !important;
  background-color: "white" !important;
}