.loading-cell::after {
    content: "";
    display: inline-block;
    animation: loadingDots 1s infinite;
}



.loading-dots::after {
    content: "Loading";
    display: inline-block;
    animation: loadingDotsWord 1s infinite;
}

@keyframes loadingDots {
    0% {
        content: ".";
    }

    33% {
        content: "..";
    }

    66% {
        content: "...";
    }

}

@keyframes loadingDotsWord {
    0% {
        content: "Loading.";
    }

    33% {
        content: "Loading..";
    }

    66% {
        content: "Loading...";
    }
}

.pi-spinner {
    animation: loadingIcon 1s infinite;
}

@keyframes loadingIcon {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}