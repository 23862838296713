.notification-bell-container {
    position: relative;
    display: flex;
    align-items: center;
}

.notification-button.p-button.p-button-text:enabled:hover {
    color: #ffffff;
    background: rgba(255, 255, 255, 0.1);
}

.notification-panel.p-overlaypanel {
    width: 300px;
}

.notification-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem;
    border-bottom: 1px solid #dee2e6;
}

.notification-header h3 {
    margin: 0;
    font-size: 1.2rem;
}

.notification-list {
    max-height: 300px;
    overflow-y: auto;
}

.notification-item {
    padding: 0.75rem;
    border-bottom: 1px solid #dee2e6;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.notification-item:hover {
    background-color: #f8f9fa;
}

.notification-item.unread {
    background-color: #e9ecef;
}

.notification-item p {
    margin: 0;
    margin-bottom: 0.25rem;
}

.notification-item small {
    color: #6c757d;
}

.notification-title {
    margin: 0;
    font-size: 1rem;
    font-weight: 600;
    color: #495057;
    margin-bottom: 0.25rem;
}

.notification-message {
    margin: 0;
    margin-bottom: 0.25rem;
    color: #6c757d;
    white-space: pre-line;
    word-break: break-word;
}

.notification-content {
    flex: 1;
    margin-right: 0.5rem;
}

.notification-delete-btn {
    padding: 0.25rem !important;
    width: 2rem !important;
    height: 2rem !important;
    visibility: visible !important;
    opacity: 0.7;
    margin-left: 0.5rem;
    transition: opacity 0.2s ease, background-color 0.2s ease;
}

.notification-item:hover .notification-delete-btn {
    opacity: 1;
    background-color: rgba(220, 53, 69, 0.1) !important;
}

.notification-delete-btn:hover {
    background-color: rgba(220, 53, 69, 0.2) !important;
}

.notification-delete-btn .p-button-icon {
    font-size: 0.875rem;
    color: #dc3545;
}

@media (max-width: 991px) {
    .notification-bell-mobile {
        margin-right: 1rem;
    }

    /* Only modify the panel positioning */
    .p-overlaypanel {
        left: 50% !important;
        transform: translateX(-50%) !important;
    }
}

.notification-header-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.5rem;
}

.notification-action-icon {
    font-size: 0.875rem;
    color: #6c757d;
}

.notification-item.clickable {
    cursor: pointer;
}

.notification-item.clickable:hover {
    background-color: #f8f9fa;
}

.notification-action-btn {
    padding: 0.25rem !important;
    width: 2rem !important;
    height: 2rem !important;
    margin-left: 0.5rem;
}

.notification-action-btn .p-button-icon {
    font-size: 0.875rem;
    color: #6c757d;
}

.notification-action-btn:hover .p-button-icon {
    color: #0d6efd;
}

.notification-time {
    color: #6c757d;
    font-size: 0.75rem;
    display: block;
    margin-top: 0.25rem;
} 