#holes-table {
    font-size: 0.8rem
}

#hole-cell {
    width: 38px;
}

@media screen and (max-width: 768px) {
    #holes-table {
        font-size: 0.7rem;
    }

    #hole-cell {
        width: 28px;
    }

}