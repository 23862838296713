* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    margin: 0;
    padding: 0;
    min-height: 100vh;
    overflow-y: auto !important;
}

.container-fluid {
    margin: 0;
    padding: 0;
    border-radius: 0;
}

.login-container {
    display: flex;
    min-height: 100vh;
    width: 100vw;
    background-color: #f5f5f5;
    margin: 0;
    padding: 0;
    border-radius: 0;
    overflow-y: auto;
}

.login-container, .logo-section {
    border-radius: 8px;
} 

.logo-section {
    display: none; /* Hidden on mobile */
    /* background: linear-gradient(135deg, #283852 0%, #0c2e3e 100%); */
    background: #283852;
    color: white;
}

.logo-content {
    padding: 10rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.company-logo {
    width: 120px;
    scale: 220%;
    height: auto;
    margin-bottom: 1.5rem;
}

.company-name {
    font-size: 2.5rem;
    margin-bottom: 1rem;
}

.company-slogan {
    font-size: 1.2rem;
    opacity: 0.9;
}

.login-section {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem;
}

.login-box {
    width: 100%;
    max-width: 400px;
    padding: 2rem;
    background: white;
    border-radius: 16px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.login-box h2 {
    font-size: 1.8rem;
    margin-bottom: 0.5rem;
    color: #333;
}

.login-subtitle {
    color: #666;
    margin-bottom: 2rem;
}

.form-group {
    margin-bottom: 1.5rem;
}

.form-group label {
    display: block;
    margin-bottom: 0.5rem;
    color: #444;
    font-weight: 500;
}

.form-input {
    width: 100%;
    padding: 0.75rem;
    border: 1.5px solid #e0e0e0;
    border-radius: 8px;
    transition: border-color 0.2s;
}

.form-input:focus {
    outline: none;
    border-color: #1a5f7a;
}

.login-button {
    width: 100%;
    padding: 0.75rem;
    background: #1a5f7a;
    color: white;
    border: none;
    border-radius: 8px;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.2s;
}

.login-button:hover {
    background: #0c2e3e;
}

.divider {
    text-align: center;
    margin: 1.5rem 0;
    position: relative;
}

.divider::before,
.divider::after {
    content: "";
    position: absolute;
    top: -26%;
    width: 45%;
    height: 1px;
    background: #e0e0e0;
}

.divider::before { left: 0; }
.divider::after { right: 0; }

.divider span {
    background: white;
    padding: 0 10px;
    color: #666;
    font-size: 0.9rem;
}

.social-buttons {
    display: flex;
    gap: 1rem;
    justify-content: center;
    margin-bottom: 1.5rem;
}

.social-button {
    width: 48px;
    height: 48px;
    border: 1.5px solid #e0e0e0;
    border-radius: 8px;
    background: white;
    cursor: pointer;
    transition: background-color 0.2s;
}

.social-button:hover {
    background: #f5f5f5;
}

.signup-prompt {
    text-align: center;
    color: #666;
    margin: 0;
}

.signup-link {
    color: #1a5f7a;
    text-decoration: none;
    margin-left: 0.5rem;
    font-weight: 500;
}

.signup-link:hover {
    text-decoration: underline;
}

/* Responsive Design */
@media (min-width: 1024px) {
    .logo-section {
        display: flex;
        width: 45%;
        align-items: center;
    }

    .login-section {
        width: 55%;
    }
}

/* Dark mode support */
@media (prefers-color-scheme: dark) {
    .login-container {
        background-color: #1a1a1a;
    }

    .login-box {
        background: #242424;
    }

    .login-box h2,
    .form-group label {
        color: #fff;
    }

    .login-subtitle,
    .signup-prompt {
        color: #999;
    }

    .form-input {
        background: #333;
        border-color: #444;
        color: #fff;
    }

    .divider span {
        background: #242424;
        color: #999;
    }

    .social-button {
        background: #333;
        border-color: #444;
    }

    .social-button:hover {
        background: #444;
    }
}

.error-message {
    background-color: #fee2e2;
    border: 1px solid #ef4444;
    color: #dc2626;
    padding: 0.75rem;
    border-radius: 8px;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 0.875rem;
}

.error-message i {
    font-size: 1rem;
}

.input-error {
    border-color: #ef4444 !important;
    background-color: #fef2f2;
}

.input-error:focus {
    border-color: #ef4444 !important;
    box-shadow: 0 0 0 1px #ef4444;
}

.login-button:disabled {
    background-color: #9ca3af;
    cursor: not-allowed;
}

/* Dark mode support */
@media (prefers-color-scheme: dark) {
    .error-message {
        background-color: #471818;
        border-color: #dc2626;
        color: #fca5a5;
    }

    .input-error {
        background-color: #471818;
        border-color: #dc2626 !important;
    }

    .input-error:focus {
        border-color: #dc2626 !important;
        box-shadow: 0 0 0 1px #dc2626;
    }

    .company-name-mobile {
        color: #fff !important;
    }
}

.safari-warning {
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #fff3cd;
    color: #856404;
    padding: 16px 24px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    border: 1px solid #ffeeba;
    font-size: 14px;
    max-width: 90%;
    text-align: center;
    z-index: 1000;
    animation: slideUp 0.3s ease-out;
}

.warning-content {
    position: relative;
    padding-right: 24px;
}

.warning-instructions {
    font-size: 12px;
    margin-top: 8px;
    color: #666;
}

.warning-close {
    position: absolute;
    top: -8px;
    right: -8px;
    background: none;
    border: none;
    color: #856404;
    cursor: pointer;
    padding: 4px;
    font-size: 16px;
    opacity: 0.7;
    transition: opacity 0.2s;
}

.warning-close:hover {
    opacity: 1;
}

@keyframes slideUp {
    from {
        transform: translate(-50%, 100%);
        opacity: 0;
    }
    to {
        transform: translate(-50%, 0);
        opacity: 1;
    }
}

/* Add mobile company name styles */
.company-name-mobile {
    text-align: center;
    font-size: 2.5rem;
    color: #283852;
    margin-bottom: 2rem;
    display: block;
}

@media (min-width: 1024px) {
    .company-name-mobile {
        display: none;
    }
}