.dropdown-toggle:disabled {
  color: #fff;
  pointer-events: none;
  background-color: #6c757d;
  border-color: #6c757d;
  opacity: 0.65;
}

.dropdown-menu {
  overflow-y: auto;
  max-height: 200px;
}