.mistake-table {
    width: 100%;
    max-width: 180px;
    min-width: 150px;
    min-height: 274px;
    /* Set a max-width to prevent the tables from becoming too wide */
    margin-bottom: 20px;
    /* Adjust the margin as needed to create spacing between tables */

}

.mistake-table th,
.mistake-table td {
    padding: 10px;
    text-align: center;
}

.mistake-container {
    display: flex;
    align-items: center;
    /* Adjust the gap between tables as needed */
}





@media screen and (min-width: 1150px) {
    .mistake-table {
        margin: 10px;
    }
}

@media screen and (max-width: 1150px) {
    .mistake-container {
        flex-direction: column;
    }

    .mistake-table th,
    .mistake-table td {
        max-width: 140px;
    }

    .stats-table th,
    .stats-table td {
        max-width: 10px;
    }

    .stats-table {
        font-size: 0.9rem;
    }

}

.stats-table th:first-child,
.stats-table td:first-child {
    text-align: left;
}

.stats-table th:not(:first-child),
.stats-table td:not(:first-child) {
    text-align: center;
}