.scorecard-table {
    text-align: center;
}

.scorecard-table {
    border: 2px solid rgb(122, 122, 122);
    border-radius: 8px;
    border-collapse: separate;
}



.scorecard-table td,
.scorecard-table th {
    border: 1px solid rgb(206, 206, 206);
    border-radius: 2px;
    border-collapse: separate;
}



@media screen and (max-width: 768px) {

    .scorecard-table td:first-child,
    .scorecard-table th:first-child {
        max-width: 35px
    }


    .scorecard-table td:not(:first-child),
    .scorecard-table th:not(:first-child) {
        max-width: 20px
    }

}